<template>
 <div :class="windowClass">
   <v-container>
        <v-row no-gutters>
      <v-col
        md="6"
        offset-md="3"
      >
        <v-card
          dark
          class="loginbg"
          style="min-height: 700px !important;"
          tile
        >
                    <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
            <v-card style="background: none;" class="pa-2 elevation-0">
              <p class="text-center mt-10">
              <img :src="$store.state.systemlogo" width="60px"/>
              </p>
              <v-card-text class="pa-0">
                    <div class="ma-10">
                      <v-text-field
                        v-model="username"
                        :rules="nameRules"
                        append-icon="mdi-account"
                        label="Username"
                        outlined
                        required
                      ></v-text-field>
                   
                      <v-text-field
                      outlined
                        v-model="password"
                        :rules="nameRules"
                        append-icon="mdi-key-variant"
                        label="Password"
                        type="password"
                        v-on:keyup.enter="validate()"
                        required
                      ></v-text-field>
                    </div>
                    
              </v-card-text>
              <v-card-actions>
                  <v-spacer/>
                  <v-btn
                        color="#fff"
                        fab
                        x-large
                        light
                        id="loginbtn"
                        class="font-weight-black"
                        @click="validate"
                      >
                        PLAY!
                      </v-btn>
                       <v-spacer/>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
   </v-container>
  </div>
</template>
<script>
import md5 from 'md5'
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      valid: true,
      username: '',
      nameRules: [
        v => !!v || 'Field is required'
      ],
      password: '',
      snackbar: false,
    }),
    created() {
    //   if (this.$session.exists()) {
    //       this.setLoggedIn(false)
    //       this.$session.destroy()
    //   }
     this.setLoggedIn(true) 
    },
    computed: {
          windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      
      validate () {
        if (this.$refs.form.validate()) {
          this.login()
        }
      },
      login(){
         var param = {
            Username: this.username,
            Password: md5(this.password)
          }
          this.$http.post("user/login",param).then(response => {
                if(response.data.status == true) { 
                    this.$router.push('game-dashboard')
                }
          }).catch(e => {
           console.log(e)
         })
        
          
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>